#root {
    background: #f5f5f5;
    font-family: Roboto, sans-serif;
    padding-top: 121px;
}
.color-primary {
    color: #0058a0 !important;
}


nav.navbar {
    padding: 30px 0;
    background: white;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1;
}
nav.navbar .nav-item {
    color: #0058a0;
    padding: 0 10px;
}
nav.navbar .nav-item .nav-link {
    text-transform: uppercase;
    font-weight: bold;
}
nav.navbar .nav-item.call-to-action {
    background: #c7072c;
    border-radius: 50px;
    margin-left: 15px;
}
nav.navbar .nav-item:not(.call-to-action) .nav-link {
    color: #0058a0 !important;
}
nav.navbar .nav-item.call-to-action .nav-link {
    color: white;
}

section {
    margin: 75px 0;
}
section:last-of-type {
    margin-bottom: 0;
}
section.text-blue, section .text-blue {
    color: #0058a0;
}
section.text-red, section .text-red {
    color: #c7072c;
}
section .text-black {
    color: black;
}
section.bg-white, section .bg-white {
    background: white;
}
section.text-w-500, section .text-w-500 {
    font-weight: 500;
}
section.text-s-2 {
    font-size: 1.2em;
}
section .h-center {
    display: block;
    margin: 0 auto;
    width: 300px;
}
h2,
h4 {
    text-transform: uppercase;
    color: #0058a0;
    padding: 15px 0;
}
h2::after,
h4::after {
    display: block;
    content: " ";
    margin-top: 15px;
    border: 2px solid #c7072c;
    width: 50px;
    border-radius: 4px;
}
h6 {
    color: #0058a0;
    text-transform: uppercase;
    font-weight: bold !important;
}
section ol {
    list-style: none;
    counter-reset: item;
}
section ol li {
    counter-increment: item;
    margin-bottom: 5px;
}
section ol li:before {
    margin-right: 10px;
    content: counter(item) ".";
    font-weight: bold;
    color: #0058a0;
    width: 1.2em;
    text-align: center;
    display: inline-block;
}
section .cards .wrapper {
    height: 100%;
    background: white;
    padding: 30px;
}
section .cards .wrapper h2 {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 50px 0 10px;
}
section .before-footer {
    padding: 50px;
}
section footer {
    background: #0058a0;
    padding: 50px;
    color: white;
}
section .copy {
    font-size: 0.9rem;
    color: #575757;
    text-align: center;
    padding: 20px 0;
}

.card {
    border-radius: 0 !important;
    border: none !important;
    padding: 20px 20px;
    margin-bottom: 20px;
}
.card.card-bordered {
    border-radius: 20px !important;
}
input:not([type="checkbox"]),
textarea,
div.input:not(.unstyled) {
    margin-top: 10px !important;
}

div.input.header {
    color: #0058a0;
    font-weight: bold;
}

input:not([type="submit"]):not([type="checkbox"]),
textarea,
div.input:not(.unstyled) {
    background-color: white;
    width: 100%;
    border-radius: 20px;
    padding: 7px 20px;
}

a.lc-btn,
button.submit,
input[type="submit"] {
    border: none;
    border-radius: 40px;
    padding: 7px 15px;
    text-transform: uppercase;
    background-color: #0058a0;
    color: white !important;
    text-decoration: none !important;
}
a.lc-btn,
button.submit:disabled,
input[type="submit"]:disabled {
    background-color: #4B7495;
}
a.lc-btn.red,
input[type="submit"].red {
    background-color: #c7072c;
}

.messenger {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
    overflow-x: hidden;
}

.messenger .message-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
}

.messenger .message-wrapper.user {
    align-items: flex-end;
}
.messenger .message-wrapper.admin {
    align-items: flex-start;
}
.messenger .message-wrapper .file {
    text-decoration: underline;
}
.messenger .message-wrapper.user .file {
    color: white;
}
.messenger .message-wrapper.admin .file {
    color: black;
}

.messenger .message-wrapper .message {
    max-width: 70%;
    display: inline-flex;
    white-space: pre-line;
    border-radius: 20px;
    padding: 10px 15px;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

.messenger .message-wrapper.user .message {
    background-color: #0058a0;
    color: white;
}

.messenger .message-wrapper.admin .message {
    background-color: #f5f5f5;
    color: black;
}

.auto-height-textarea-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.auto-height-textarea {
    width: calc(100% - 120px);
    background: #f5f5f5;
    padding: 10px 15px;
    border-radius: 20px;
    border: none;
    font-size: 16px;
    margin-bottom: 5px;
    max-height: 150px;
}

label.checkbox {
    display: flex;
    align-items: center;
}

label.checkbox input[type="checkbox"] {
    width: auto;
    margin-right: 7px;
    margin-top: 0 !important;
}

#popup-wrapper {
    display: flex;
    justify-content: flex-end;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 200px;
}

#popup {
    position: absolute;
    right: 15px;
    top: -225px;
    width: 220px;
    background: white;
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
}

#popup .popup-header {
    position: absolute;
    left: 0;
    top: 30px;
    font-size: 28px;
    text-align: center;
    width: 100%;
    color: white;
    font-weight: lighter;
}

#popup .popup-call {
    color: black;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    padding-top: 10px;
}

#popup .popup-number {
    color: black;
    font-size: 25px;
    text-align: center;
    padding-bottom: 20px;
}

.alert-primary {
    color: #0058a0 !important;
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1rem;
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: .5;
}
.form-control.is-valid, .was-validated .form-control:valid {
    border-color: #0058a0 !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cpath fill='%230058a0' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") !important;
}
.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    box-shadow: 0 0 0 0.2rem #b3cee5 !important;
}
.form-check-input.is-valid~.form-check-label {
    color: black !important;
}

.dzu-dropzone {
    margin-top: 10px !important;
    border: 1px solid #ced4da !important;
}

.dzu-dropzone-preview.file {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dzu-dropzone .dzu-inputLabel {
    color: #0058a0 !important;
}

.dzu-dropzone-preview.file .name {
    font-size: 14px;
}

.dzu-dropzone-preview.file button {
    background: none;
    border: none;
    color: #c7072c;
}

@media (max-width: 991px) {
    #popup {
        right: unset !important;
        left: 50%;
        transform: translateX(-50%);
        top: -300px !important;
    }
}
